export const navItems = [
  {
    title: 'Auctions',
    url: '/overview#topAnchor',
  },
  {
    title: 'Docs',
    url: '/docs#topAnchor',
  },
]
